@font-face {
	font-family: 'Gilroy'; 
	src: url('fonts/Gilroy-Light.ttf'); 
    font-weight: 300;
}
@font-face {
	font-family: 'Gilroy'; 
	src: url('fonts/Gilroy-Regular.ttf'); 
    font-weight: 400;
}
@font-face {
	font-family: 'Gilroy'; 
	src: url('fonts/Gilroy-Medium.ttf'); 
    font-weight: 500;
}
@font-face {
	font-family: 'Gilroy'; 
	src: url('fonts/Gilroy-Bold.ttf'); 
    font-weight: 600;
}
.right{
    text-align: right;
}
.center{
    text-align: center;
}
.grid{
    display: grid;
    height: 100%;
    vertical-align: middle;
    align-items: center;
}
body{
    padding: 0px;
    margin: 0px;
    font-family: 'Gilroy';
    background-color: #fff;
}
button{
    cursor: pointer;
    background-color: rgba(242, 100, 33, 1);
    border: none;
    font-size: 15px;
    padding: 8px 40px;
    color: white;
    border-radius: 20px;
}
.body-wrapper{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.sidebar-body{
    padding: 20px 20px 10px 20px;
    flex: 0 0 calc(100% - 330px);
}
.open-menu{
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
}
.sidebar-wrapper img{
    display: none;
}
.editorClassName{
    background-color: white;
    border-radius: 0px 0px 10px 10px;
}
@media(max-width: 960px){
    .open-menu{
        display: block;
    }
    .user-add .right{
        text-align: left !important;
    }
    .user-add .right button{
        margin-bottom: 10px;
        margin-left: 0px !important;
    }
    .user-add form{
        margin-bottom: 15px;
    }
    .sidebar-wrapper img{
        display: inline-block;
        width: 40px;
    }
    .sidebar-body{
        margin-top: 40px;
    }
    .body-wrapper{
        display: block;
    }
    .sidebar-wrapper{
        position: fixed;
        top: 0;
        left: -300px;
        z-index: 9;
    }
    .sidebar-wrapper.active{
        left: 0;
    }
    .sidebar-body{
        z-index: 1;
        position: relative;
    }
}

.desc-cost{
    color: rgba(0,0,0,0.4);
    text-decoration: line-through;
}
.course-learn{
    border: 1px solid rgba(0,0,0,0.15);
    margin-top: 5px;
    border-radius: 5px;
    padding: 10px 10px 10px 15px;
    background-color: rgba(255,255,255,0.6);
}
.prepods-list span{
    font-size: 14px;
}
.prepods-list select{
    width: calc(100% - 25px);
    margin-right: 10px;
    border: 1px solid rgba(0,0,0,0.25);
    font-size: 14px;
    margin-top: 4px;
    padding: 7px 10px;
    border-radius: 5px;
    height: 54px;
}
.suitable-form{
    margin-top: 7px;
    margin-bottom: 5px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 10px 10px 10px 15px;
    background-color: rgba(255,255,255,0.6);
    border-radius: 5px;
}
.suitable-form label{   
    margin-bottom: 0px !important;
}
.suitable-form p{
    margin: 0px;
    text-align: right;
    text-decoration: underline;
    font-size: 14px;
    margin-top: 7px;
    line-height: 1;
}
.suitable-form p span{
    cursor: pointer;
}

/* ------------------------------------------------ */

.user-add input{
    margin-right: 10px;
    border: 1px solid rgba(0,0,0,0.25);
    font-size: 16px;
    margin-top: 4px;
    padding: 7px 10px;
    border-radius: 5px;
}

/* ------------------------------------------------ */
.table-list{
    background-color: #f3f3f5;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    height: calc(100vh - 166px);
    max-height: calc(100vh - 120px);
    overflow-x: hidden;
    overflow-y: auto;
}
table.delivery{
    overflow: hidden;
    background-color: rgba(255,255,255,0.5);
    border-radius: 10px;
    width: 100%; 
    border: 0px; 
    border-collapse: collapse;
}

table.delivery td{
    border-right: 1px solid rgba(0,0,0,0.1);
}
table.delivery thead th:last-child,
table.delivery tr td:last-child{
    border-right: 0px solid black;
}
table.delivery thead {
    text-align: left;
    position: sticky;
    top: 0;
    background-color: white;
}
table.delivery thead th{
    font-weight: 500;
    border-right: 1px solid rgba(0,0,0,0.1);
    padding: 10px;
}
table.delivery td {
    padding: 0.6rem 1rem; 
    border-bottom: 1px solid #e8e9eb;
}

.group__table-list{
    height: calc(100vh - 225px);
    max-height: calc(100vh - 120px);
}

@media screen and (max-width: 800px) {
    table.delivery thead {
      display: none;
    }
    table.delivery tr {
      display: block;
      margin-bottom: 1rem;
      border-bottom: 2px solid #e8e9eb;
    }
    table.delivery td {
      display: block;
      text-align: right;
    }
    table.delivery td:before {
      content: attr(aria-label);
      float: left;
      font-weight: bold;
    }
    .table-list{
        height: auto;
        max-height: none;
    }
}

/* ------------------------------------------------ */
form.login-form{
    background-color: #f3f3f5;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    padding: 20px;
    transform: translate(-50%, -50%);
    width: 400px;
    max-width: calc(100% - 60px);
}
.login-form h2{ 
    margin-top: 0px;
}
.login-form input{
    border: 1px solid rgba(0,0,0,0.25);
    font-size: 16px;
    margin-top: 4px;
    padding: 10px;
    width: calc(100% - 20px);
    border-radius: 5px;
}
.login-form label{
    margin-bottom: 19px;
    display: block;
}

/* ------------------------------------------------ */
.sidebar-wrapper{
    background-color: #1d2327;
    flex: 0 0 250px;
    height: calc(100vh - 40px);
    padding: 20px 20px;
    max-width: 250px;
}
.sidebar-wrapper ul{
    list-style: none;
    padding-left: 0px;
}
.sidebar-wrapper a{
    color: white;
    text-decoration: none;
    padding: 12px 0px;
    display: block;
    font-size: 16px;
    background-size: 20px;
    background-position: left 5px center;
    padding-left: 40px;
    background-repeat: no-repeat;
}
.sidebar-wrapper a:hover{
    background-color: rgba(0,0,0,0.2);
}
.sidebar-wrapper ul li:nth-child(1) a{
    background-image: url('./images/user.svg');
}
.sidebar-wrapper ul li:nth-child(2) a{
    background-image: url('./images/group.svg');
}
.sidebar-wrapper ul li:nth-child(3) a{
    background-image: url('./images/mail.svg');
}
.sidebar-wrapper ul li:nth-child(4) a{
    background-image: url('./images/learn.svg');
}
.sidebar-wrapper ul li:nth-child(5) a{
    background-image: url('./images/photo.svg');
}
.sidebar-wrapper ul li:nth-child(6) a{
    background-image: url('./images/photo.svg');
}
.sidebar-wrapper ul li:nth-child(7) a{
    background-image: url('./images/learn.svg');
}
.sidebar-wrapper ul li:nth-child(8) a{
    background-image: url('./images/order.svg');
}
.sidebar-wrapper ul li:nth-child(9) a{
    background-image: url('./images/pen.svg');
}
.sidebar-wrapper ul li:nth-child(10) a{
    background-image: url('./images/statistics.svg');
}
.sidebar-wrapper ul li:nth-child(11) a{
    background-image: url('./images/setting.svg');
}
.sidebar-wrapper ul li:nth-child(12) a{
    background-image: url('./images/exit.svg');
}

/* ------------------------------------------------ */
.popup-form{
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.popup-form>div{
    max-height: 99%;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 15px;
    width: 700px;
    max-width: calc(100% - 40px);
    transform: translate(-50%, -50%);
}
.popup-close{
    text-align: right;
}
.popup-close img{
    cursor: pointer;
    width: 50px;
}
.excel-des{
    font-size: 13px;
}
.adduser-form{
    max-height: calc(100vh - 150px);
    overflow-x: hidden;
    overflow-y: auto;
}
.popup-form form label{
    display: block;
    margin-bottom: 20px;
}
.popup-form.mini>div{
    width: 450px;
}
.popup-form form label select,
.popup-form form label textarea,
.popup-form form label input,
.adduser-form__item input{
    width: 100%;
    resize: none;
    border: 1px solid rgba(0,0,0,0.25);
    font-size: 15px;
    margin-top: 4px;
    padding: 10px;
    width: calc(100% - 25px);
    border-radius: 5px;
}
.adduser-form__item-del{
    text-align: right;
    margin-top: 5px;
}
.adduser-form__item-del{
    text-decoration: underline;
    cursor: pointer;
}
.adduser-form{
    background-color: #f3f3f5;
    border-radius: 5px;
    padding: 10px 5px;
    margin-bottom: 15px;
}
.adduser-form__item{
    border-bottom: 1px solid rgba(0,0,0,0.15);
    padding: 10px 0px;
}

/* ------------------------------------------------ */
.loader-wrapper{
    background-color: white;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.loader-wrapper>div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loader {  
    width: 75px;
    height: 75px;
    margin: 0px auto;
    border-radius: 50%;
    perspective: 800px;
}
.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;  
}  
.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid rgba(242, 100, 33, 1);
}  
.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid rgba(242, 100, 33, 1);
}  
.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid rgba(242, 100, 33, 1);
}  
@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}
@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}  
@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}


/* pagination --------------------------- */
.pagination{
    margin-top: 20px;
}
.pagination>div{
    margin: 0px 3px;
}
.pagination__item{
    text-align: center;
    display: inline-block;
    border: 1px solid #868686;
    border-radius: 10px;
    overflow: hidden;
}
.pagination__item.dots{ 
    padding: 5px 10px;
    min-width: 20px;
    color: black;
}
.pagination__item>*{
    padding: 7px 10px 4px 10px;
    min-width: 20px;
    color: black;
    display: block;
}
.pagination__item.active span,
.pagination__item:hover span{
    color: white;
}
.pagination__item.active span,
.pagination__item span:hover{
    cursor: pointer;
    color: white;
    background-color: rgba(0, 119, 255, 1);
}

/* ------------------------------------------------ */
.group-info{
    background-color: #f3f3f5;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
}
.group-info h1{
    margin-top: 0px;
    font-size: 26px;
    margin-bottom: 0px;
}
.group-info p{
    margin-top: 10px;
    margin-bottom: 0px;
}

/* ------------------------------------------------ */
.popup-order__item{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

/* ------------------------------------------------ */
.media-item{
    width: calc(100% - 10px);
    padding-top: 100%;
    position: relative;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
}
.media-item video{
    position: absolute;
    top: calc(50% - 80px);
}
.media-item>div{
    cursor: pointer;
    display: none;
    background-color: #f3f3f5;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: -5px;
    right: -5px;
    position: absolute;
}
.media-item:hover div{
    display: block;
}
.media-item>div img{
    width: 40px;
}

/* ------------------------------------------------ */
.setting-body{
    background-color: #f3f3f5;
    padding: 10px;
    border-radius: 10px;
}
.setting-body label{  
    display: block;
}
.setting-body input{
    border: 1px solid rgba(0,0,0,0.25);
    font-size: 15px;
    margin-top: 4px;
    padding: 10px;
    width: calc(100% - 25px);
    border-radius: 5px;
    margin-bottom: 20px;
}  

/* ------------------------------------------------ */
.courses-desc{
    background-color: #f3f3f5;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 175px;
    overflow-x: hidden;
    overflow-y: auto;
}
.courses-module{
    background-color: #f3f3f5;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    height: calc(100vh - 285px);
    overflow-x: hidden;
    overflow-y: auto;
}
.courses-desc__btn{
    text-align: right;
}
.courses-desc h1{
    margin-top: 5px;
    margin-bottom: 5px;
}
.courses-desc p{
    height: 75px;
    margin-bottom: 0px;
    overflow: hidden;
}

/* ------------------------------------------------ */
.test-wrapper{
    background-color: rgba(255,255,255,0.75);
    border: 1px solid rgba(0,0,0,0.25);
    padding: 10px;
    border-radius: 5px;
    margin-top: 7px;
    margin-bottom: 35px;
}
.question-item__wrapper{
    padding: 10px 0px;
}
.question-item__wrapper p{
    text-align: right;
    font-size: 14px;
    margin: 0px;
}   
.question-item__wrapper p span{
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
}
.question-item__wrapper select{
    width: 100%;
    resize: none;
    border: 1px solid rgba(0,0,0,0.25);
    font-size: 15px;
    margin-top: 4px;
    padding: 6px 5px;
    width: calc(100% - 15px);
    border-radius: 5px;
}
.answ-list__input label{
    margin-bottom: 5px !important;
}
.answ-list{
    border: 1px solid rgba(0,0,0,0.15);
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 10px;
}
.answ-list h2{
    font-size: 17px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.label-title{
    font-size: 20px;
    text-align: center;
    display: block;
    margin-top: 30px;
    margin-bottom: 15px;
}
.answ-list__chk{
    display: flex;
    height: 45px;
}
.answ-list input{
    resize: none;
    border: 1px solid rgba(0, 0, 0, 0.25);
    font-size: 15px;
    margin-top: 4px;
    padding: 10px;
    width: calc(100% - 25px);
    border-radius: 5px;
}
.answ-list__chk input{
    max-width: 100% !important;
    margin-top: 0px;
    display: block;
    height: 24px;
    position: relative;
    top: 11px;
    width: 24px !important;
    margin-bottom: 0px;
}
.answ-list__input{
    width: calc(100% - 69px);
    margin-left: 8px;
}
.type2-qw{
    width: calc(100% - 30px);
}
.type2-del,
.answ-list__del{
    width: 30px;
}
.type2-del img,
.answ-list__del img{
    width: 30px;
    margin-top: 7px;
}

.lesson-test{
    max-height: calc(100vh - 325px);
}
@media(max-height: 760px){
    .lesson-test{
        max-height: auto;
    }
}
.test-list__item{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 10px 5px;
}
.test-list__name span{
    font-size: 14px;
}
.sidebar-back{
    text-align: right;
    color: white;
}
.sidebar-back a{
    margin: 0px;
    display: contents;
    padding: 0px;
    cursor: pointer;
}

.courses-desc__btn a{
    display: inline-block;
    cursor: pointer;
    background-color: rgba(242, 100, 33, 1);
    border: none;
    font-size: 15px;
    padding: 8px 40px;
    color: white;
    border-radius: 20px;
    margin-right: 15px;
    text-decoration: none;
    background-color: black;
}
.courses-desc__btn button{
    margin-bottom: 10px;
}

/* ------------------------------------------------ */
.statistics-wrapper{
    max-height: calc(100vh - 40px);
    overflow-x: hidden;
    overflow-y: auto;
}
.statistics-body{
    background-color: #f3f3f5;
    padding: 10px;
    border-radius: 10px;
}
.statistics-body input{
    border-radius: 10px;
    border: 1px solid #868686;
    margin-bottom: 20px;
    width: calc(100% - 40px);
    padding: 10px;
}